import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

if (!store.state.company.companyItem.length) {
  // 直接触发仓库中的方法 获取公司模块的数据
  await store.dispatch('company/getCompanyItem')
}

const temp = store.state.company.companyItem[0]
// console.log(temp)
const routes = [
  // 配置一级路由
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      title: temp.myTitle,
      content: {
        keywords: temp.keywords,
        description: temp.description
      }
    }
  },
  {
    path: '/about',
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/case',
    component: () => import('@/views/CasePage.vue')
  },
  {
    path: '/casedetail',
    component: () => import('@/views/details/CaseDetail.vue')
  },
  {
    path: '/serve',
    component: () => import('@/views/ServePage.vue')
  },
  {
    path: '/contact',
    component: () => import('@/views/ContactPage.vue')
  }
]
const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   // 如果之前有保存滚动位置，则恢复到之前保存的位置
    //   return savedPosition
    // } else {
    // 否则，默认滚动到页面顶部
    return { x: 0, y: 0 }
    // }
  }
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
