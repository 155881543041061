import { GetContact, SubMess } from '@/api/contact'

export default {
  namespaced: true,
  state: {
    contactItem: [] // 联系我们的信息
  },
  mutations: {
    setcontactItem (state, item) {
      state.contactItem = item
    }
  },
  actions: {
    // 获取服务项目的信息
    async getcontactItem ({ commit }) {
      const res = await GetContact()
      // console.log(res)
      commit('setcontactItem', res.results[0])
    },
    // 提交在线留言
    async submess ({ commit }, item) {
      const res = await SubMess(item)
      return res
    }
  }
}
