import { GetServe } from '@/api/serve'

export default {
  namespaced: true,
  state: {
    serveItem: [] // 服务项目的信息
  },
  mutations: {
    setserveItem (state, item) {
      state.serveItem = item
    }
  },
  actions: {
    // 获取服务项目的信息
    async getserveItem ({ commit }) {
      const res = await GetServe()
      // console.log(res)
      commit('setserveItem', res.results[0])
    }
  }
}
