<template>
  <div>
    <!-- 底部区域 -->
    <div class="foot">
      <div class="footer container">
        <div class="footer_item" v-if="$store.state.company.companyItem.length">
          <div class="left">
            <img @click="$router.push('/index')" :src="$store.state.company.companyItem[0].foot_logo" alt="">
            <ul>
              <li>
                <img src="../assets/image/foot1.png" alt="">
                <p>电话：{{$store.state.company.companyItem[0].phone}}</p>
              </li>
              <li>
                <img src="../assets/image/foot2.png" alt="">
                <p>邮箱：{{$store.state.company.companyItem[0].email}}</p>
              </li>
              <li>
                <img src="../assets/image/foot3.png" alt="">
                <p>地址：{{$store.state.company.companyItem[0].address1}}</p>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li>
                <img :src="$store.state.company.companyItem[0].kf_ewm" alt="">
                <p>扫码加客服微信</p>
              </li>
              <li>
                <img :src="$store.state.company.companyItem[0].gz_ewm" alt="">
                <p>扫码关注公众号</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer_bei" v-if="$store.state.company.companyItem.length">
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">Copyright © {{$store.state.company.companyItem[0].time}} {{$store.state.company.companyItem[0].name}} {{$store.state.company.companyItem[0].ICP}} 版权所有</a>
      </div>
    </div>
    <!-- 底部区域 -->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
//底部区域
.foot{
  background-color: #2174CA;
  .footer{
    padding-top: 70px;
  .footer_item{
    display: flex;
    justify-content: space-between;
    .left{
      img{
        width: 227px;
        height: 69px;
        cursor: pointer;
      }
      ul{
        li{
          display: flex;
          margin: 40px 0;
          img{
            width: 26px;
            height: 27px;
          }
          p{
            font-size: 20px;
            color: #FFFFFF;
            margin-left: 20px;
          }
        }
      }
    }
    .right{
      ul{
        display: flex;
        li{
          margin-left: 50px;
          img{
            width: 160px;
            height: 160px;
          }
          p{
            text-align: center;
            margin-top: 20px;
            font-size: 20px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
  .footer_bei{
    width: 100%;
    height: 86px;
    background-color: #2174CA;
    border-top: 1px solid rgba(217,217,217,0.6);
    font-size: 20px;
    text-align: center;
    line-height: 86px;
    a{
      color: #D9D9D9;
    }
}
}
</style>
