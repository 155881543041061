import { GetCase, GetCaseItem, GetCaseDetail } from '@/api/cases'

export default {
  namespaced: true,
  state: {
    indexCaseItem: [], // 首页经典案例信息
    caseOthItem: {}, // 经典案例的其它信息
    caseItem: {}// 经典案例展示的信息
  },
  mutations: {
    setindexCaseItem (state, item) {
      state.indexCaseItem = item
    },
    setcaseOthItem (state, item) {
      state.caseOthItem = item
    },
    setcaseItem (state, item) {
      state.caseItem = item
    }
  },
  actions: {
    // 获取首页经典案例信息
    async getindexCaseItem ({ commit }, item) {
      const res = await GetCase(item)
      // console.log(res)
      commit('setindexCaseItem', res.results)
    },
    // 获取经典案例其它信息
    async getOthcase ({ commit }) {
      const res = await GetCaseItem()
      // console.log(res)
      commit('setcaseOthItem', res.results[0])
    },
    // 获取经典案例的展示信息
    async getcaseitem ({ commit }, item) {
      const res = await GetCase(item)
      // console.log(res)
      commit('setcaseItem', res)
    },
    // 获取案例详情
    async  getcasedetail ({ commit }, id) {
      const res = await GetCaseDetail(id)
      return res
    }
  }
}
