import { GetAbout } from '@/api/about'

export default {
  namespaced: true,
  state: {
    aboutItem: [] // 服务项目的信息
  },
  mutations: {
    setaboutItem (state, item) {
      state.aboutItem = item
    }
  },
  actions: {
    // 获取服务项目的信息
    async getaboutItem ({ commit }) {
      const res = await GetAbout()
      // console.log(res)
      commit('setaboutItem', res.results[0])
    }
  }
}
