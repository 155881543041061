import { GetCompany, GetNav } from '@/api/company'

export default {
  namespaced: true,
  state: {
    companyItem: [], // 公司模块信息
    navItem: [] // 导航栏信息
  },
  mutations: {
    setcompanyItem (state, item) {
      state.companyItem = item
    },
    setnavItem (state, item) {
      state.navItem = item
    }
  },
  actions: {
    // 获取公司模块信息
    async getCompanyItem ({ commit }) {
      const res = await GetCompany()
      // console.log(res)
      commit('setcompanyItem', res.results)
    },
    // 获取导航栏信息
    async getNav ({ commit }) {
      const res = await GetNav()
      commit('setnavItem', res.results)
    }
  }
}
