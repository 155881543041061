<template>
  <div>
    <!-- 头部 -->
    <div class="head">
      <div class="top_page container" >
        <p v-if="companyItem.length">欢迎来到{{companyItem[0].name}}</p>
        <ul>
          <li>
            <img src="../assets/image/zixun.png" alt="">
            <p v-if="companyItem.length">{{companyItem[0].helpline}}</p>
          </li>
          <!-- <li>
            <img src="../assets/image/user.png" alt="">
            <p>登录</p>
          </li>
          <li>
            <img src="../assets/image/bi.png" alt="">
            <p>注册</p>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- 头部 -->

    <!-- 头部导航 -->
    <div class="nav">
      <div class="nav_con container">
        <img @click="$router.push('/index')" v-if="companyItem.length" :src="companyItem[0].logo" alt="">
        <ul>
          <li @click="changenav(index,item.path)" :class="{active:checked === index}" v-for="(item,index) in navItem" :key="item.id" >{{item.name}}</li>
        </ul>
      </div>
    </div>
    <!-- 头部导航 -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      nav_list: [
        { title: '首页', path: '/index' },
        { title: '服务内容', path: '/serve' },
        { title: '经典案例', path: '/case' },
        { title: '关于淘鱼', path: '/about' },
        { title: '联系我们', path: '/contact' }
      ],
      checked: 0,
      companyItem: [], // 公司模块信息
      navItem: [] // 导航栏信息
    }
  },
  created () {
    if (this.$route.path === '/serve') {
      this.checked = 1
    }
    if (this.$route.path === '/about') {
      this.checked = 3
    }
    if (this.$route.path === '/case' || this.$route.path === '/casedetail') {
      this.checked = 2
    }
    if (this.$route.path === '/contact') {
      this.checked = 4
    }
    if (!this.$store.state.company.companyItem.length) {
      this.getCompany()
    } else {
      this.companyItem = this.$store.state.company.companyItem
    }
    // 获取导航栏的信息
    if (!this.$store.state.company.navItem.length) {
      this.getNav()
    } else {
      this.navItem = this.$store.state.company.navItem
    }
  },
  methods: {
    changenav (index, path) {
      this.checked = index
      this.$router.push(path)
    },
    // 获取公司模块的信息
    async  getCompany () {
      await this.$store.dispatch('company/getCompanyItem')
      this.companyItem = this.$store.state.company.companyItem
      // console.log(this.companyItem, 111)
    },
    // 获取导航栏信息
    async getNav () {
      await this.$store.dispatch('company/getNav')
      this.navItem = this.$store.state.company.navItem
    }
  }
}
</script>

<style lang="less" scoped>
//头部
.head{
  width: 100%;
  background-color: #2174CA;
  height: 70px;
  .top_page{
    background-color: #2174CA;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      font-size: 18px;
      color: #FFFFFF;
    }
    ul{
      display: flex;
      li{
        display: flex;
        align-items: center;
        margin-left: 48px;
        img{
          width: 23px;
          height: 20px;
          margin-right: 10px;
        }
        p{
          font-size: 16px;
        }
      }
    }
  }
}
//头部导航
.nav{
  width: 100%;
  // background-color: #f4f8fb;
  height: 90px;
  .nav_con{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #f4f8fb;
    height: 90px;
    img{
      width: 166px;
      height: 50px;
      cursor: pointer;
    }
    ul{
      display: flex;
      li{
        cursor: pointer;
        font-size: 20px;
        color: #3F3F3F;
        margin-left: 70px;
        height: 50px;
        line-height: 50px;
        &:hover{
          border-bottom: 3px solid #2174CA;
          font-weight: 700;
          color: #2174CA;
        }
      }
      .active{
        border-bottom: 3px solid #2174CA;
        font-weight: 700;
        color: #2174CA;
      }
    }
  }
}
</style>
