import { GetIndex } from '@/api/home'

export default {
  namespaced: true,
  state: {
    indexItem: {} // 首页信息
  },
  mutations: {
    setindexItem (state, item) {
      state.indexItem = item
    }
  },
  actions: {
    // 获取首页信息
    async getIndexItem ({ commit }) {
      const res = await GetIndex()
      // console.log(res)
      commit('setindexItem', res)
    }
  }
}
