import Vue from 'vue'
import Vuex from 'vuex'
import company from './modules/company'
import home from './modules/home'
import cases from './modules/cases'
import serve from './modules/serve'
import about from './modules/about'
import contact from './modules/contact'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    company,
    home,
    cases,
    serve,
    about,
    contact
  }
})

export default store
