import Vue from 'vue'
import HeadNav from '@/components/HeadNav.vue'
import FooterNav from '@/components/FooterNav.vue'
import BaiduMap from 'vue-baidu-map'
import {
  Carousel,
  CarouselItem,
  Pagination,
  Message
} from 'element-ui'

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(BaiduMap, {
  ak: 'xRr37vc5LX8DKcAP2c9NqEmksY39QYvX' // 这个地方是官方提供的ak密钥
})
// 全局注册组件
Vue.component('HeadNav', HeadNav)
Vue.component('FooterNav', FooterNav)
Vue.prototype.$message = Message
