import request from '@/utils/request'

// 获取案例展示
export const GetCase = (data) => request.get('/cases/displays/', { params: data })

// 获取经典案例的其它信息
export const GetCaseItem = () => request.get('/cases/cases/')

// 获取案例详情
export const GetCaseDetail = (id) => request.get(`/cases/displays/${id}/`)
